import { createSelector } from 'reselect'
import _ from 'lodash'

const contractSelector = state => state?.contract || {}

export const getDataContract = createSelector(
    contractSelector,
    contracts => contracts.data || {}
)

export const getContractId = createSelector(
    getDataContract,
    data => data.id
)

export const getSouscripteurContract = createSelector(
    getDataContract,
    data => data.souscripteur || {}
)

export const getSouscripteurLastNameContract = createSelector(
    getSouscripteurContract,
    data => data.lastname
)


export const getSouscripteurTitreContract = createSelector(
    getSouscripteurContract,
    data => data.titre
)

export const getSouscripteurFirstNameContract = createSelector(
    getSouscripteurContract,
    data => data.firstname
)

export const getSouscripteurEmailContract = createSelector(
    getSouscripteurContract,
    data => data.email
)

export const getTiersPayeurContract = createSelector(
    getDataContract,
    data => data.tiers_payeur
)

export const getSouscriptionLinkContract = createSelector(
    getDataContract,
    data => data.souscription_link
)
export const getSouscripteurNomContract = createSelector(
    getSouscripteurContract,
    data => data.nom
)

export const getSouscripteurNClientContract = createSelector(
    getSouscripteurContract,
    data => data.id
)

export const getSouscripteurVilleNaissance = createSelector(
    getSouscripteurContract,
    data => data.ville_naissance
)

export const getSouscripteurPaysNaissance = createSelector(
    getSouscripteurContract,
    data => data.pays_naissance
)

export const getSouscripteurCodePaysNaissance = createSelector(
    getSouscripteurContract,
    data => data.code_pays_naissance
)

export const getSouscripteurNomNaissance = createSelector(
    getSouscripteurContract,
    data => data.nom_naissance
)

export const getVehiculeContract = createSelector(
    getDataContract,
    data => data.vehicule || {}
)

export const getIsACLContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_ACL' || vehicule.codeProduit === '229127_AC' || vehicule.codeProduit === '229127_CC' || vehicule.codeProduit === '229127_PR' || vehicule.codeProduit === '229127_FR'
)

export const getIsWakamContract = createSelector(
    getDataContract,
    contract => contract?.compagnie === "WAKAM"
)

export const getIsFleetContract = createSelector(
    getDataContract,
    data => data.flotte
)

export const getUsageVehiculeContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.usage
)

export const getCylindreeVehiculeContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.cylindree
)

export const getImmatVehiculeContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.immatriculation
)

export const getCodeProduitContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit
)

export const getIsVAEContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_VAE'
)

export const getIsNVEIContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_NVEI'
)

export const getIsAIMCOContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit === '7311_QMCO' || vehicule.codeProduit === '7311_2MCO'
)

export const getLieuStationnementCodePostalContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.lieuStationnementCodePostal
)

export const getLieuStationnementVilleContract = createSelector(
    getVehiculeContract,
    vehicule => vehicule.lieuStationnementVille
)

export const getContractGarantiesOptions = createSelector(
    getDataContract,
    data => data.garanties.map(garantie => {
        const correctedFranchise = garantie.franchise.replace(/Franchise de (\s*)€/i, 'Franchise de 0€');

        return {
            ...garantie,
            franchise: correctedFranchise
        };
    })
);

export const getContractHamon = createSelector(
    getDataContract,
    data => data.hamon
)

export const getGarantiesContract = createSelector(
    getDataContract,
    data => _.filter(data.garanties, ['type', 'G'])
)

export const getOptionsContract = createSelector(
    getDataContract,
    data => _.filter(data.garanties, ['type', 'O'])
)

export const getContractSituation = createSelector(
    getDataContract,
    data => data.situation || {}
)

export const getMotifSituationContract = createSelector(
    getContractSituation,
    situation => situation.motif
)

export const getDateFinSituationContract = createSelector(
    getContractSituation,
    situation => situation.dateFin
)

export const getContractStatut = createSelector(
    getContractSituation,
    situation => situation.statut
)


export const getDateAvtContract = createSelector(
    getContractSituation,
    data => data.date
)

export const getNumeroPoliceContract = createSelector(
    getDataContract,
    data => data.numeroPolice
)

export const getContractCRM = createSelector(
    getVehiculeContract,
    vehicule => vehicule.crm
)

export const getContractCRMMoto = createSelector(
    getVehiculeContract,
    vehicule => vehicule.crmMotoAct
)

export const getContractCodeProduit = createSelector(
    getVehiculeContract,
    vehicule => vehicule.codeProduit
)

export const getContractEcheance = createSelector(
    getContractSituation,
    situation => situation.echeance
)

export const getContractDateEcheance = createSelector(
    getContractSituation,
    situation => situation.dateEcheance
)

export const getContractDateAffaireNouvelle = createSelector(
    getContractSituation,
    situation => situation.dateAffaireNouvelle
)

export const getContractFractionnement = createSelector(
    getContractSituation,
    situation => situation.fractionnement
)

export const getContractFractionnementLabel = createSelector(
    getContractSituation,
    situation => _.endsWith(situation.fractionnement, 'mensuel') ? 'mensuel' : 'annuel'
)

export const getContractStatutCode = createSelector(
    getContractSituation,
    situation => situation.statutCode
)

export const getNbPiecesContract = createSelector(
    getDataContract,
    data => data.nb_pieces
)

export const getUploadLinkContract = createSelector(
    getDataContract,
    data => data.upload_link
)

export const getUploadAppLinkContract = createSelector(
    getDataContract,
    data => data.upload_app_link
)

export const getExportReceiptsLinkContract = createSelector(
    getDataContract,
    data => data.receipts_link
)

export const getRiLinkContract = createSelector(
    getDataContract,
    data => data.ri_link
)

export const getRiLinkReasonContract = createSelector(
    getDataContract,
    data => data.ri_link_reason[0]
)

export const getApporteur1Contract = createSelector(
    getDataContract,
    data => data.apporteur1
)

export const getApporteurNomContract = createSelector(
    getDataContract,
    data => data.apporteurNom
)

export const getAvisEcheanceLinkContract = createSelector(
    getDataContract,
    data => data.avis_echeance_link
)

export const getAvisEcheanceLinkReasonContract = createSelector(
    getDataContract,
    data => data.avis_echeance_link_reason[0]
)

export const getAccidentClaimLinkContract = createSelector(
    getDataContract,
    data => data.accident_claim_link
)

export const getAdhesionPrincipaleContract = createSelector(
    getDataContract,
    data => data.adhesionPrincipale
)

export const getPrelevementContract = createSelector(
    getDataContract,
    data => data.prelevement
)

export const getHivernagesContract = createSelector(
    getDataContract,
    data => data.hivernages || []
)

export const getDernierHivernageContract = createSelector(
    getHivernagesContract,
    hivernages => hivernages[0] || null
)

export const getIsEligibleHivernageContract = createSelector(
    getDataContract,
    data => data.is_eligible_hivernage || false
)

export const getCanActivateHivernageContract = createSelector(
    getDataContract,
    data => data.can_activate_hivernage || false
)

export const getAvantageContract = createSelector(
    getDataContract,
    data => data.avantage
)
