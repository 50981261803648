import React, {Fragment} from 'react';
import './inmotion.scss'
import AprilInMotion from '../../../../../../assets/images/avantages/april_in&motion.png'
import InMotionCover from '../../../../../../assets/images/avantages/in&motion_cover.png'
import {Link} from "react-router-dom";

const InMotion = (props) => {
    const { user: {code, eligible}, postRequestCode, code_promo } = props

    return (
        <div className={'col-xl-11 col-md-12 pe-0'}>
            <div className={'mt-5'}>
                <h6 className={'text-primary-light'}><Link to={'/'} className={'text-decoration-none'}>Mon espace</Link> - <Link to={'/mes-avantages'} className={'text-decoration-none'}>Mes avantages</Link> - <Link to={'/mes-avantages/in&motion'} className={'text-decoration-none'}>In&Motion</Link></h6>
            </div>
            <div className={'mt-5 pb-2'}>
                <h1 className={'fw-bold'}><Link to={'/mes-avantages'}><i className="bi bi-arrow-left-circle me-2" /></Link>In&Motion</h1>
            </div>

            <div className={'row border border-color-primary p-5'}>
                <div className="col-md-4">
                    <img src={InMotionCover} alt="Application" width={250} className='w-100'/>
                </div>
                <div className="col-md-8">
                    <img src={AprilInMotion} alt="Application" width={250}/>
                    <h4 className={'text-primary-light fw-bold mt-4'}>Achetez un airbag et <strong>bénéficiez de 3 mois
                        de location offerts à la In&box*</strong></h4>
                    <p className={'mt-4 h5 fw-normal'}>APRIL Moto et son partenaire In&Motion s’engagent en faveur de
                        votre sécurité avec une offre exclusive ! </p>

                    <p className={'mt-4 h5 fw-bold'}>Comment bénéficier de cette offre ?</p>
                    <p className={'h5 fw-normal'}>1. Récupérer votre code exclusif ci-dessous</p>
                    <p className={'h5 fw-normal'}>2. Connectez-vous sur le site In&Motion</p>
                    <p className={'h5 fw-normal'}>3. Choisissez la formule REVOLUTION ANNUELLE</p>
                    <p className={'h5 fw-normal'}>4. Renseignez votre code exclusif</p>

                    {/* Attente de la MEP IN&MOTION
                    <p className={'mt-4 h5 fw-bold'}>Comment bénéficier de cette offre ?</p>
                    <p className={'h5 fw-normal mt-2 d-inline-block'}>1. Après avoir souscrit une assurance Moto ou
                        Scooter APRIL Moto et acheté un airbag doté de la technologie In&motion</p>
                    <p className={'h5 fw-normal mt-2 d-inline-block'}>2. Rendez-vous sur le site <a
                        href="http://www.inemotion.com/fr" target={"_blank"}>http://www.inemotion.com/fr</a> ou en
                        téléchargeant l’application « My In&box » (disponible sur l’App store ou Play store)</p>
                    <p className={'h5 fw-normal mt-2 d-inline-block'}>3. Cliquer sur l’onglet « Adhésion » depuis le
                        site internet ou « Créer un compte » depuis l’appli, puis cliquez sur le logo « APRIL Moto »</p>
                    <p className={'h5 fw-normal mt-2 d-inline-block'}>4. Saisissez votre e-mail et l’immatriculation de
                        votre véhicule assuré APRIL Moto et poursuivez la création de votre compte client</p>

                    */}

                    <div className='bloc-avantage'>
                        {eligible ? <Fragment>
                            <h3 className={'mt-5'}>Votre code exclusif :</h3>
                            {code && <p className={'code'}>{code}</p>}
                            {code_promo && <p className={'code'}>{code_promo}</p>}
                            {(!code && !code_promo && eligible) && <p className={'code code-ask'} onClick={() => postRequestCode({campaign: 'I&MAPRIL'})}>Obtenir ma réduction</p>}
                        </Fragment> : <p>Vous n'êtes pas éligible à l'offre proposée.</p>}
                    </div>
                    <a className="btn btn-secondary mt-4 text-uppercase" target={'_blank'} href="https://www.april-moto.com/april-moto-et-inemotion-vous-offrent-3-mois-delocation-a-inebox/">Détails de l'offre</a>
                </div>
            </div>
        </div>
    );
};

export default InMotion;
